































































































import Vue from 'vue'
import { Component, Prop, Watch } from 'vue-property-decorator'
import { SearchData } from '@/store/search/types'
import BaseForm from '@/components/forms/BaseForm.vue'
import { Form, KeyValue } from '@/store/commonFormInput/types'
import InputTypes from '@/common/formTypes'
import SearchResult from '@/components/search-results/SearchResult.vue'
import { parseDateToOutputDate } from '@/common/globals'
import { BButton } from 'bootstrap-vue'
import { State } from 'vuex-class'

@Component({
  components: { SearchResult, BaseForm }
})
/*
The general search agent component can be used by different search agents.
It shows a list of search agents and it's action buttons like delete and edit.
@group SEARCH AGENT
 */
export default class SearchAgent extends Vue {
  $refs!: {
    submitButton: BButton;
  }

  @State('currentScreenWidth') screenWidth: number

  // the search data for the job search agent
  @Prop({ required: true })
  searchData: SearchData

  // unique identifier
  @Prop({ required: true })
  id: string

  // if a delete button is shown
  @Prop({ default: false })
  showDelete: boolean

  // used for a role rights check if the user is allowed to delete and save search agents
  @Prop({ default: true })
  hasSaveDeleteRight: boolean

  // edit mode for the search agent
  @Prop({ default: false })
  editMode: boolean

  // set true for use in a modal
  @Prop({ required: true })
  useInModal: boolean

  @Watch('searchData', { immediate: true })
  onPropsChanged (value: SearchData): void {
    this.internalModel = value
    this.internalModel.endDate = value.endDate ? new Date(value.endDate) : null
    this.internalModel.startDate = typeof value.startDate === 'string' ? new Date(value.startDate) : value.startDate
  }

  @Watch('editMode', { immediate: true })
  onModeChanged (value: boolean): void {
    this.isEditModeInternalModel = value
  }

  get fields (): Form[] {
    return [
      {
        id: 'searchName',
        key: 'searchName',
        type: InputTypes.TEXT,
        required: true,
        label: this.$t('search-agent.name').toString(),
        autofocus: true,
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      },
      {
        id: 'interval',
        key: 'interval',
        type: InputTypes.SELECT,
        required: true,
        label: this.$t('search-agent.interval').toString(),
        options: this.intervals,
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      },
      {
        id: 'startDate',
        key: 'startDate',
        type: InputTypes.DATE,
        required: true,
        label: this.$t('search-agent.start-date').toString(),
        state: this.validationState.startDateState,
        disabledDates: {
          start: undefined,
          end: this.internalModel.endDate ? this.internalModel.endDate : undefined
        },
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      },
      {
        id: 'endDate',
        key: 'endDate',
        type: InputTypes.DATE,
        required: true,
        label: this.$t('search-agent.end-date').toString(),
        state: this.validationState.endDateState,
        disabledDates: {
          start: this.internalModel.startDate !== null ? this.internalModel.startDate : undefined,
          end: undefined
        },
        md: this.useInModal && this.screenWidth < 1230 ? 12 : 6
      }
    ]
  }

  get intervals (): KeyValue[] {
    return [
      {
        value: 'DAILY',
        text: this.$t('search-agent.DAILY').toString()
      },
      {
        value: 'WEEKLY',
        text: this.$t('search-agent.WEEKLY').toString()
      },
      {
        value: 'IN14DAYS',
        text: this.$t('search-agent.IN14DAYS').toString()
      },
      {
        value: 'MONTHLY',
        text: this.$t('search-agent.MONTHLY').toString()
      }
    ]
  }

  parseDateToOutputDate (date: Date | string): string | undefined {
    return parseDateToOutputDate(date, this.$i18n.locale, false)
  }

  internalModel: SearchData = {
    searchAgentId: '',
    interval: '',
    searchName: '',
    startDate: new Date(),
    endDate: null
  }

  validationState = {
    endDateState: undefined,
    startDateState: undefined
  }

  isEditModeInternalModel = this.editMode

  onDeleteClick (): void {
    this.$emit('delete', this.internalModel.searchAgentId)
  }

  validate (): void {
    this.$set(this.validationState, 'endDateState', this.internalModel.endDate !== null)
    this.$set(this.validationState, 'startDateState', this.internalModel.startDate !== null)
  }

  submit (): void {
    this.validate()
    if (this.validationState.endDateState) {
      this.$emit('submit', this.internalModel)
    }
  }
}
